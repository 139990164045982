<template>
  <div
    class="modal fade"
    :id="`modalInlineError_${id}`"
    tabindex="-1"
    data-bs-backdrop="static"
    data-bs-keyboard="false"
    :aria-labelledby="`modalInlineErrorLabel_${id}`"
    aria-hidden="true"
  >
    <div
      class="modal-dialog sx-modal-responsive"
      :class="{
        'w-mw-90': !$isMobile(),
      }"
    >
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" :id="`modalInlineErrorLabel_${id}`">
            <i :class="modal.icon"></i>
            {{ modal.title }}
          </h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <div
            v-for="(message, i) in inlineErrors"
            :key="message"
            class="alert alert-danger mt-2 mb-1"
            role="alert"
          >
            {{ ++i }}. {{ message }}
          </div>
          <table
            v-if="informations && informations.length > 0"
            :class="{
              'mt-0': !inlineErrors,
              'mt-2': inlineErrors,
            }"
            class="table table-primary fs-5 mb-2"
          >
            <thead>
              <tr>
                <th>
                  {{
                    $t(
                      "Components.ValidationMessages.Type",
                      {},
                      { locale: this.$store.state.activeLang }
                    )
                  }}
                </th>
                <th>
                  {{
                    $t(
                      "Components.ValidationMessages.FieldName",
                      {},
                      { locale: this.$store.state.activeLang }
                    )
                  }}
                </th>
                <th>
                  {{
                    $t(
                      "Components.ValidationMessages.Message",
                      {},
                      { locale: this.$store.state.activeLang }
                    )
                  }}
                </th>
              </tr>
            </thead>
            <tbody>
              <tr class="table-info" :key="i" v-for="(item, i) in informations">
                <td>
                  {{
                    $t(
                      "BaseModelFields.Information",
                      {},
                      { locale: this.$store.state.activeLang }
                    )
                  }}
                </td>
                <td v-if="!String.isNullOrWhiteSpace(item.fieldName)">
                  {{ item.fieldName }}
                </td>
                <td v-else>{{ $valueNotAvailableSeparator }}</td>
                <td>{{ item.message }}</td>
              </tr>
            </tbody>
          </table>
          <BusinessRuleResponseGrid
            :parentClasses="inlineErrors ? 'mt-2' : ''"
            :errors="
              businessRuleResponse && businessRuleResponse.businessRuleResponses
            "
            :response="businessRuleResponse"
          />
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="btn btn-danger btn-sm"
            data-bs-dismiss="modal"
          >
            <i class="fas fa-times"></i>
            {{
              $t(
                "BaseModelFields.Close",
                {},
                { locale: this.$store.state.activeLang }
              )
            }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import BusinessRuleResponseGrid from "@/components/custom/set-pages/BusinessRuleResponseGrid.vue";
export default {
  name: "InlineErrorModal",
  props: ["id", "inlineErrors", "informations", "businessRuleResponse"],
  data() {
    return {
      modal: {
        title: this.$t(
          "Components.InlineErrorModal.Title",
          {},
          { locale: this.$store.state.activeLang }
        ),
        icon: "bi-file-text",
      },
    };
  },
  components: {
    BusinessRuleResponseGrid,
  },
};
</script>
