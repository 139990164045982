<template>
  <div
    class="view-edit-mode"
    :class="{
      hidden: isDetailPage,
    }"
  >
    <div class="position-relative">
      <textarea
        ref="textAreaRef"
        class="form-control overflow-hidden"
        rows="1"
        v-model="textAreaValue"
        :spellcheck="$isTextSpellCheck"
        :class="
          element.valueClassNames !== undefined ? element.valueClassNames : ''
        "
        :id="element.id"
        :name="element.id"
        :disabled="isDisabled"
        :required="isRequired"
        :placeholder="placeholder"
        :data-oldvalue="oldValue"
        @keyup="textareaOnKeyup"
      ></textarea>
      <div class="invalid-tooltip">
        {{
          this.$t(
            "FieldWarnings.TextNotValid",
            {},
            { locale: this.$store.state.activeLang }
          )
        }}
      </div>
    </div>
  </div>
  <div class="view-detail-mode" v-if="isDetailPage">
    <span class="d-flex text-break">{{
      String.isNullOrWhiteSpace(textAreaValue)
        ? this.$valueNotAvailableSeparator
        : textAreaValue
    }}</span>
  </div>
</template>
<script>
export default {
  name: "TextBox",
  props: ["element", "field", "value", "crudType", "isCellEdit", "rowData"],
  data() {
    return {
      isNewPage: this.crudType === "NEW",
      isDetailPage: this.crudType === "DETAIL",
      placeholder: this.field.fieldType.includes("Formula")
        ? this.$t(
            "BaseModelFields.FieldIsCalcForSystem",
            {},
            { locale: this.$store.state.activeLang }
          )
        : "",
      textAreaValue: this.value,
      isDisabled:
        !this.field.isActive ||
        this.element.isDisabled ||
        this.field.fieldType.includes("Formula") ||
        (this.$route.meta.isEditPage && !this.field.isEditable),
      isRequired: this.field.isRequired || this.element.isRequired,
      oldValue: "",
    };
  },
  methods: {
    setValue(isSuccess, value) {
      this.textAreaValue = value;
      if (isSuccess) {
        this.oldValue = value;
      }
    },
    textareaOnKeyup() {
      var values = this.textAreaValue.split("\n");
      this.$refs.textAreaRef.setAttribute("rows", values.length);
    },
  },
  mounted() {
    if (
      !this.field.fieldType.includes("Formula") &&
      !String.isNullOrWhiteSpace(this.value)
    ) {
      this.textAreaValue = this.value;
      this.oldValue = this.value;
    }

    var textArea = this.$refs.textAreaRef;
    // 0: Sınırsız (Unlimited)
    if (this.field.length > 0) {
      textArea.setAttribute("maxlength", this.field.length);
    }
  },
};
</script>
