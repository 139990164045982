<template>
  <template v-if="disabled">
    <div class="p-2 bg-gray-400 border-gray-400">
      <label :for="template.id" class="block cursor-not-allowed w-100 fs-6">
        <div class="text-center text-wrap">
          {{
            $t(
              "BaseModelFields." + template.field.fieldType,
              {},
              { locale: this.$store.state.activeLang }
            )
          }}
          {{
            $t(
              "Components.FileUploadModal.FileInputDescription",
              {},
              { locale: this.$store.state.activeLang }
            )
          }}
        </div>
      </label>
      <template v-if="selectedFiles.length > 0">
        <div class="d-grid">
          <ul class="list-group mt-2 mb-2">
            <li
              class="list-group-item text-wrap fs-6 p-1"
              v-for="file in selectedFiles"
              :key="file"
            >
              {{ file.name }}
            </li>
          </ul>
        </div>
      </template>
      <template v-if="uploadedFiles.length > 0">
        <ul class="list-group mt-2 mb-2">
          <li
            class="list-group-item bg-success text-white fs-6 text-wrap p-1"
            v-for="file in uploadedFiles"
            :key="file"
          >
            {{ file.name }}<br />
            (<small>
              {{
                $t(
                  "BaseModelFields.FileUploaded",
                  {},
                  { locale: this.$store.state.activeLang }
                )
              }}</small
            >)
          </li>
        </ul>
      </template>
      <template v-if="uploadedFilesForMountedItems.length > 0">
        <ul class="list-group mt-2 mb-2">
          <li
            class="list-group-item bg-success text-white fs-6 text-wrap p-1"
            :data-id="file.id"
            v-for="file in uploadedFilesForMountedItems"
            :key="file.id"
          >
            {{ file.name }}<br />
            (<small>
              {{
                $t(
                  "BaseModelFields.FileUploaded",
                  {},
                  { locale: this.$store.state.activeLang }
                )
              }}</small
            >)
          </li>
        </ul>
      </template>
    </div>
  </template>
  <template v-else>
    <div
      class="p-2 bg-gray-100 border-gray-300"
      @dragover="dragover"
      @dragleave="dragleave"
      @drop="drop"
    >
      <input
        type="file"
        :id="template.id"
        :name="template.id"
        @change="onFileSelected"
        ref="fileInput"
        class="hidden"
        :accept="acceptedFileType"
      />

      <label :for="template.id" class="block cursor-pointer w-100 fs-6">
        <div class="text-center text-wrap">
          {{
            $t(
              "BaseModelFields." + template.field.fieldType,
              {},
              { locale: this.$store.state.activeLang }
            )
          }}
          {{
            $t(
              "Components.FileUploadModal.FileInputDescription",
              {},
              { locale: this.$store.state.activeLang }
            )
          }}
        </div>
      </label>
      <template v-if="selectedFiles.length > 0">
        <div class="d-grid">
          <ul class="list-group mt-2 mb-2">
            <li
              class="list-group-item text-wrap fs-6 p-1"
              v-for="file in selectedFiles"
              :key="file"
            >
              {{ file.name }}
              <button
                class="btn btn-danger btn-xs float-end"
                type="button"
                @click="remove(selectedFiles.indexOf(file))"
              >
                <i class="ms-2 fas fa-trash fs-6 cursor-pointer"></i>
              </button>
            </li>
          </ul>
          <button
            type="button"
            class="btn btn-success btn-xs mb-2 float-end"
            @click="onUpload"
            v-if="!uploadButtonDisabled"
          >
            <span>
              {{
                $t(
                  "Components.FileUploadModal.Upload",
                  {},
                  { locale: this.$store.state.activeLang }
                )
              }}
            </span>
          </button>
          <div
            class="spinner-border spinner-border-sm mb-2 text-center text-primary"
            role="status"
            v-if="uploadButtonDisabled"
          >
            <span class="visually-hidden">
              {{
                $t(
                  "BaseModelFields.Loading",
                  {},
                  { locale: this.$store.state.activeLang }
                )
              }}</span
            >
          </div>
        </div>
      </template>
      <template v-if="uploadedFiles.length > 0">
        <ul class="list-group mt-2 mb-2">
          <li
            class="list-group-item bg-success text-white fs-6 text-wrap p-1"
            v-for="file in uploadedFiles"
            :key="file"
          >
            {{ file.name }}<br />
            (<small>
              {{
                $t(
                  "BaseModelFields.FileUploaded",
                  {},
                  { locale: this.$store.state.activeLang }
                )
              }}</small
            >)
            <button
              class="btn btn-danger btn-xs float-end"
              type="button"
              @click="removeUploadedFile(uploadedFiles.indexOf(file))"
            >
              <i class="ms-2 fas fa-trash fs-6 cursor-pointer"></i>
            </button>
          </li>
        </ul>
      </template>
      <template v-if="uploadedFilesForMountedItems.length > 0">
        <ul class="list-group mt-2 mb-2">
          <li
            class="list-group-item bg-success text-white fs-6 text-wrap p-1"
            :data-id="file.id"
            v-for="file in uploadedFilesForMountedItems"
            :key="file.id"
          >
            {{ file.name }}<br />
            (<small>
              {{
                $t(
                  "BaseModelFields.FileUploaded",
                  {},
                  { locale: this.$store.state.activeLang }
                )
              }}</small
            >)
            <button
              class="btn btn-danger btn-xs float-end"
              type="button"
              @click="
                removeUploadedFileForMountedItem(
                  uploadedFilesForMountedItems.indexOf(file)
                )
              "
            >
              <i class="ms-2 fas fa-trash fs-6 cursor-pointer"></i>
            </button>
          </li>
        </ul>
      </template>
    </div>
  </template>
</template>
<script>
import { createToast } from "mosha-vue-toastify";
import $ from "jquery";
export default {
  name: "FileInput",
  props: ["template", "disabled", "value", "valueId", "rowData"],
  watch: {
    uploadedFiles: {
      deep: true,
      handler() {
        if (this.selectedFiles.length == 0) {
          var value = this.uploadedFiles
            .map(function (m) {
              return m.documentPublicId + "," + m.name;
            })
            .join(this.$systemSeparator);

          this.template.parent.editCellTemplateValueChangedForFileInput(
            this.template,
            value
          );
        }
      },
    },
    uploadedFilesForMountedItems: {
      deep: true,
      handler() {
        var value = this.uploadedFilesForMountedItems
          .map(function (m) {
            return m.id + "," + m.name;
          })
          .join(this.$systemSeparator);
        this.template.parent.editCellTemplateValueChangedForFileInput(
          this.template,
          value
        );
      },
    },
  },
  data() {
    return {
      selectedFiles: [],
      uploadedFiles: [],
      uploadedFilesForMountedItems: [],
      notUploadedFiles: [],
      errors: [],
      uploadButtonDisabled: false,
      isImageFile: !this.template.field.fieldType.includes("File"),
      acceptedFileType: this.template.field.fieldType.includes("File")
        ? "*"
        : "image/*",
    };
  },
  methods: {
    getErrorLocalizeMessage(msg) {
      if (
        msg === "file_extention_is_not_allowed_please_update_your_configuration"
      ) {
        return this.$t(
          "BaseModelFields.FileExtensionIsNotAllowed",
          {},
          { locale: this.$store.state.activeLang }
        );
      }
      return msg;
    },
    onFileSelected() {
      if (!this.template.field.isMultipleValue) {
        this.selectedFiles = [];
        this.selectedFiles.push(this.$refs.fileInput.files[0]);
      } else {
        this.selectedFiles = [...this.$refs.fileInput.files];
      }
      this.errors = [];
    },
    dragAndDropOnChange() {
      if (!this.template.field.isMultipleValue) {
        this.selectedFiles = [];
        this.selectedFiles.push(this.$refs.fileInput.files[0]);
      } else {
        this.selectedFiles = [...this.$refs.fileInput.files];
      }
    },
    remove(i) {
      this.selectedFiles.splice(i, 1);
    },
    removeUploadedFileForMountedItem(i) {
      this.uploadedFilesForMountedItems.splice(i, 1);
    },
    removeUploadedFile(i) {
      this.uploadedFiles.splice(i, 1);
    },
    dragover(event) {
      event.preventDefault();
      if (!event.currentTarget.classList.contains("bg-green-300")) {
        event.currentTarget.classList.remove("bg-gray-100");
        event.currentTarget.classList.add("bg-green-300");
      }
    },
    dragleave(event) {
      event.currentTarget.classList.add("bg-gray-100");
      event.currentTarget.classList.remove("bg-green-300");
    },
    drop(event) {
      event.preventDefault();
      this.$refs.fileInput.files = event.dataTransfer.files;
      this.dragAndDropOnChange();
      event.currentTarget.classList.add("bg-gray-100");
      event.currentTarget.classList.remove("bg-green-300");
    },
    onUpload() {
      this.uploadButtonDisabled = true;

      var self = this;
      this.errors = [];
      this.notUploadedFiles = [];

      this.selectedFiles.forEach(function (file, i) {
        var formData = new FormData();
        formData.append(
          "CustomObjectPublicId",
          self.template.field.customObjectPublicId
        );
        formData.append("FieldPublicId", self.template.field.publicId);
        formData.append("Extention", "." + file.name.split(".").pop());
        formData.append("Name", file.name);
        formData.append("File", file);

        self.$appAxios
          .post("/rws-DocumentController-Upload", formData)
          .then((response) => {
            self.uploadButtonDisabled = false;
            var result = response.data,
              fileName = file.name;

            self.selectedFiles = self.selectedFiles.filter(function (item) {
              return item.name !== fileName;
            });

            if (result.isOk) {
              file.documentPublicId = result.item.documentPublicId;
              self.uploadedFiles.push(file);
            } else {
              createToast(
                `${file.name}: ${self.getErrorLocalizeMessage(result.message)}`,
                {
                  showIcon: true,
                  position: "top-right",
                  type: "danger",
                  transition: "zoom",
                }
              );
              self.notUploadedFiles.push(file);
              self.errors.push(result.message);
            }
          })
          .catch(function (error) {
            //TODO:Error
            // alert(error);
            self.errors.push(error);
          });
      });
    },
    drawUploadedFiles() {
      this.value.split(this.$systemSeparator).forEach((element) => {
        var fileItem = element.split(",");
        this.uploadedFilesForMountedItems.push({
          id: fileItem[0],
          name: fileItem[1],
        });
      });
    },
  },
  mounted() {
    var fileInput = this.$refs.fileInput;
    if (this.template.field.isMultipleValue) {
      fileInput.setAttribute("multiple", "");
    }

    if (!String.isNullOrWhiteSpace(this.value)) {
      this.drawUploadedFiles();
    }
  },
};
</script>
