<template>
  <div
    class="view-edit-mode"
    :class="{
      hidden: isDetailPage,
    }"
  >
    <div
      class="position-relative dx-viewport"
      :class="{
        'dx-timepicker-valid': !isInvalid,
        'dx-timepicker-invalid': isInvalid,
      }"
    >
      <DxDateBox
        class="dx-datepicker-container"
        ref="timePicker"
        :value="timePickerValueAsTime"
        :displayFormat="displayFormat"
        type="time"
        :showClearButton="false"
        :disabled="!isEnabled"
        :openOnFieldClick="true"
        :input-attr="inputAttributes"
        :onValueChanged="onValueChanged"
        :useMaskBehavior="true"
        :interval="30"
        applyValueMode="instantly"
      />
      <div class="invalid-tooltip">
        {{
          this.$t(
            "FieldWarnings.TextNotValid",
            {},
            { locale: this.$store.state.activeLang }
          )
        }}
      </div>
    </div>
  </div>
  <div class="view-detail-mode" v-if="isDetailPage">
    <span :id="field.publicId" class="d-flex text-break">{{
      String.isNullOrWhiteSpace(timePickerValueAsString)
        ? $valueNotAvailableSeparator
        : $root.timeFormat(timePickerValueAsString)
    }}</span>
  </div>
</template>
<script>
/* https://js.devexpress.com/Documentation/ApiReference/UI_Components/dxDateBox/Configuration/ */
import DxDateBox from "devextreme-vue/date-box";
export default {
  name: "TimePicker",
  data() {
    return {
      displayFormat: null,
      inputAttributes: {
        class: `form-control ${
          String.isNullOrWhiteSpace(this.element.valueClassNames)
            ? ""
            : this.element.valueClassNames
        }`,
        id: this.element.id,
        name: this.element.id,
      },
      formatInfo: this.$root.getDateTimeFormat(),
      timePickerValueAsTime: null,
      timePickerValueAsString: null,
      isNewPage: this.crudType === "NEW",
      isDetailPage: this.crudType === "DETAIL",
      placeholder: this.field.fieldType.includes("Formula")
        ? this.$t(
            "BaseModelFields.FieldIsCalcForSystem",
            {},
            { locale: this.$store.state.activeLang }
          )
        : this.$t(
            "BaseModelFields.ChooseTime",
            {},
            { locale: this.$store.state.activeLang }
          ),
      oldValue: "",
      isEnabled:
        this.field.isActive &&
        !this.element.isDisabled &&
        !this.field.fieldType.includes("Formula") &&
        !this.field.fieldType.includes("Rollup"),
    };
  },
  created() {
    this.displayFormat = this.formatInfo.time.replace("A", "a");
  },
  components: {
    DxDateBox,
  },
  computed: {
    isInvalid() {
      return (
        (this.element.isRequired || this.field.isRequired) &&
        String.isNullOrWhiteSpace(this.timePickerValueAsTime)
      );
    },
  },
  props: ["element", "field", "value", "crudType", "isCellEdit", "rowData"],
  methods: {
    onValueChanged(e) {
      if (String.isNullOrWhiteSpace(e.event)) return;

      /* UI Change Event Fix */
      this.$root.formItemNewChangeEvent([this.field.publicId]);

      this.timePickerValueAsTime = e.value;
    },
    setValue(isSuccess, time) {
      this.timePickerValueAsTime = this.$moment(
        time,
        this.formatInfo.time
      ).format("yyyy-MM-DDTHH:mm");

      if (isSuccess) {
        this.oldValue = time;
        this.timePickerValueAsString = time;
      }
    },
  },
  mounted() {
    if (!String.isNullOrWhiteSpace(this.value)) {
      var value = this.value,
        valueFormat = this.$root.getTimeFormatByValue(value, this.formatInfo);

      this.timePickerValueAsTime = this.$moment(value, valueFormat).format(
        "yyyy-MM-DDTHH:mm:ss"
      );

      var formatedTime = this.$moment(value, valueFormat).format(
        this.formatInfo.time
      );
      this.oldValue = formatedTime;
      this.timePickerValueAsString = formatedTime;
    }

    if (this.$route.meta.isEditPage && this.isEnabled) {
      this.isEnabled = this.field.isEditable;
    }

    var timePicker = document.getElementById(this.element.id);
    if (timePicker !== undefined) {
      if (this.element.isRequired || this.field.isRequired) {
        timePicker.setAttribute("required", "");
      }

      timePicker.setAttribute("placeholder", this.placeholder);
      timePicker.setAttribute("data-oldvalue", this.oldValue);
    }
  },
};
</script>
