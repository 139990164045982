<template>
  <input
    type="text"
    class="dx-texteditor-input shadow-none"
    :placeholder="placeholder"
    :disabled="disabled"
    :autocomplete="$isAutoComplete"
    :spellcheck="$isTextSpellCheck"
    :name="template.id"
    :id="template.id"
    v-model="textBoxValue"
    @change="textBoxOnChange"
  />
</template>
<script>
import $ from "jquery";
export default {
  name: "TextBox",
  props: ["template", "value", "disabled", "rowData"],
  data() {
    return {
      textBoxValue: this.value,
      placeholder:
        this.template.field.fieldType.includes("Formula") ||
        this.template.field.fieldType.includes("AutoId")
          ? this.$t(
              "BaseModelFields.FieldIsCalcForSystem",
              {},
              { locale: this.$store.state.activeLang }
            )
          : "",
    };
  },
  mounted() {
    //
  },
  methods: {
    textBoxOnChange() {
      this.textBoxValue = $("#" + this.template.id).val();

      this.template.parent.editCellTemplateValueChangedForTextBoxFieldTypes(
        this.template,
        this.textBoxValue
      );

      this.template.parent.$root.calculateTriggerFieldForGrid(
        this.template.parent,
        this.template
      );
    },
  },
};
</script>
