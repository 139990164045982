<template>
  <div
    class="view-edit-mode"
    :class="{
      hidden: isDetailPage,
      'html-editor-valid':
        !isRequired ||
        (isRequired && !String.isNullOrWhiteSpace(this.editorData)),
      'html-editor-invalid':
        isRequired && String.isNullOrWhiteSpace(this.editorData),
    }"
  >
    <div class="position-relative">
      <textarea
        :id="field.publicId"
        :name="field.publicId"
        class="hidden form-control"
        :value="editorData"
        :required="isRequired"
        :data-oldvalue="oldValue"
      ></textarea>
      <div class="invalid-tooltip">
        {{
          this.$t(
            "FieldWarnings.TextNotValid",
            {},
            { locale: this.$store.state.activeLang }
          )
        }}
      </div>
    </div>
  </div>
  <div class="view-detail-mode" v-if="isDetailPage">
    <span
      class="w-100 text-break ckeditor-data-view"
      v-html="editorData"
      v-if="!String.isNullOrWhiteSpace(editorData)"
    ></span>
    <span class="w-100 text-break" v-else>{{
      this.$valueNotAvailableSeparator
    }}</span>
  </div>
</template>
<script>
import $ from "jquery";
export default {
  name: "HtmlEditor",
  props: ["element", "field", "value", "crudType", "isCellEdit", "rowData"],
  data() {
    return {
      isNewPage: this.crudType === "NEW",
      isDetailPage: this.crudType === "DETAIL",
      isRequired: this.element.isRequired || this.field.isRequired,
      isDisabled:
        !this.field.isActive ||
        this.element.isDisabled ||
        (this.$route.meta.isEditPage && !this.field.isEditable),
      editorData: this.value,
      oldValue: "",
    };
  },
  methods: {
    setValue(isSuccess, value) {
      var ckEditorInstance = window.CKEDITOR.instances[this.element.id];
      ckEditorInstance.setData(value);
      this.editorData = value;
      if (isSuccess) {
        this.oldValue = value;
      }
    },
  },
  mounted() {
    if (!String.isNullOrWhiteSpace(this.value)) {
      this.editorData = this.value;
      this.oldValue = this.value;
    } else {
      this.oldValue = "";
    }

    var documentCkEditor = document.getElementById(
      "cke_" + this.element.publicId
    );
    if (this.isDetailPage && documentCkEditor !== null) {
      documentCkEditor.classList.add("hidden");
    }

    var ckeditor = window.CKEDITOR;
    if (this.field.property == 2) {
      ckeditor.replace(this.field.publicId, {
        htmlEncodeOutput: false,
        extraPlugins: "tableresize",
        contentsCss: [
          "/extras/plugins/ckeditor/contents.css",
          "/extras/plugins/ckeditor/document.css",
          "/extras/plugins/ckeditor/customfonts/fonts.css",
        ],
        bodyClass: "document-editor",
      });
    } else {
      ckeditor.replace(this.field.publicId, {
        htmlEncodeOutput: false,
        extraPlugins: "tableresize",
        contentsCss: [
          "/extras/plugins/ckeditor/contents.css",
          "/extras/plugins/ckeditor/customfonts/fonts.css",
        ],
      });
    }

    var ckEditorInstance = ckeditor.instances[this.element.id];
    if (!String.isNullOrWhiteSpace(ckEditorInstance)) {
      ckEditorInstance.config.language = this.$store.state.activeLang;
      ckEditorInstance.config.readOnly = this.isDisabled;

      ckEditorInstance.setData(this.editorData);

      ckEditorInstance.on("change", () => {
        this.editorData = ckEditorInstance.getData();
      });

      var self = this;
      ckEditorInstance.on("contentDom", function () {
        var editable = ckEditorInstance.editable();

        editable.attachListener(
          ckEditorInstance.document,
          "keyup",
          function () {
            if (self.field.property == "2") {
              var txt = ckEditorInstance.getData();
              $("#cke_1_path_label")
                .text($(txt).text().replace(/\n/g, "").length)
                .css("color", "#484848")
                .css("font-weight", "bold")
                .css("font-size", "13px")
                .css("float", "right")
                .css("margin", "0 15px 0")
                .show();
            }
          }
        );
      });
    }

    if (this.isRequired) {
      document.getElementById(this.field.publicId).setAttribute("required", "");
    }
  },
};
</script>
