<template>
  <div class="view-edit-mode" :class="{ hidden: isDetailPage }">
    <div class="position-relative">
      <input
        type="text"
        class="form-control text-end"
        :class="
          element.valueClassNames !== undefined ? element.valueClassNames : ''
        "
        :placeholder="placeholder"
        :required="isRequired"
        :disabled="isDisabled"
        :autocomplete="$isAutoComplete"
        :spellcheck="$isTextSpellCheck"
        :name="element.id"
        :id="element.id"
        :maxlength="maxLength"
        :data-oldvalue="oldValue"
        v-model="numericTextBoxValue"
        @change="numericTextBoxOnChange"
      />
      <div class="invalid-tooltip">
        {{
          this.$t(
            "FieldWarnings.TextNotValid",
            {},
            { locale: this.$store.state.activeLang }
          )
        }}
      </div>
    </div>
  </div>
  <div class="view-detail-mode" v-if="isDetailPage">
    <span :id="element.id" class="d-flex text-break">{{
      String.isNullOrWhiteSpace(numericTextBoxValue)
        ? this.$valueNotAvailableSeparator
        : numericTextBoxValue
    }}</span>
  </div>
</template>
<script>
/* https://github.com/RobinHerbots/Inputmask */
import Inputmask from "inputmask";
export default {
  name: "NumericTextBox",
  props: ["element", "field", "value", "crudType", "isCellEdit", "rowData"],
  data() {
    return {
      numericTextBoxValue: this.value,
      placeholder: this.field.fieldType.includes("Formula")
        ? this.$t(
            "BaseModelFields.FieldIsCalcForSystem",
            {},
            { locale: this.$store.state.activeLang }
          )
        : this.$root.inputMaskPatternReplace(this.field),
      isDetailPage: this.crudType === "DETAIL",
      isRequired: this.field.isRequired || this.element.isRequired,
      isDisabled:
        !this.field.isActive ||
        this.element.isDisabled ||
        this.field.fieldType.includes("Rollup") ||
        this.field.fieldType.includes("Formula") ||
        (this.$route.meta.isEditPage && !this.field.isEditable),
      maxLength: String.isNullOrWhiteSpace(this.field.inputMaskPattern.length)
        ? this.field.length
        : this.field.inputMaskPattern.length,
      formatInfo: this.$root.getNumberFormat(),
      oldValue: "",
    };
  },
  mounted() {
    var selector = document.getElementById(this.element.id);
    if (!String.isNullOrWhiteSpace(selector)) {
      var im = new Inputmask({
        alias: "decimal",
        groupSeparator: this.formatInfo.group,
        radixPoint: this.formatInfo.decimal,
        autoGroup: this.field.isThousandSeparator,
        clearMaskOnLostFocus: true,
        allowMinus: true,
        radixFocus: true,
        positionCaretOnClick: "none",
        autoUnmask: false,
        integerDigits: this.field.length,
        digits: this.field.decimalPlaces,
      });
      im.mask(selector);
    }

    if (
      !this.field.fieldType.includes("Formula") &&
      !String.isNullOrWhiteSpace(this.value) &&
      this.value !== this.$formulaErrorText
    ) {
      this.numericTextBoxValue = this.value;
      this.oldValue = this.value;
      if (this.$route.meta.isNewPage) {
        this.numericTextBoxOnChange(null, true);
      }
    }
  },
  methods: {
    setValue(isSuccess, value) {
      this.numericTextBoxValue = value;
      if (isSuccess) {
        this.oldValue = value;
      }
    },
    numericTextBoxOnChange($event, isMountedTrigger = false) {
      if (!this.isCellEdit && !isMountedTrigger) {
        this.$root.calculateTriggerField(this.field);
      }
    },
  },
};
</script>
