<template>
  <div class="dx-viewport">
    <DxDateBox
      class="dx-datepicker-container"
      ref="datePicker"
      :value="value"
      :type="type"
      :id="`${template.id}-parent`"
      :showClearButton="false"
      :disabled="disabled"
      :displayFormat="displayFormat"
      :onValueChanged="onValueChanged"
      :input-attr="inputAttributes"
      :interval="String.isNullOrWhiteSpace(interval) ? 30 : interval"
      :useMaskBehavior="true"
      :openOnFieldClick="true"
      :applyValueMode="applyValueMode"
      :placeholder="placeholder"
    />
  </div>
</template>
<script>
/* https://js.devexpress.com/Documentation/ApiReference/UI_Components/dxDateBox/Configuration/ */
import DxDateBox from "devextreme-vue/date-box";
export default {
  name: "DatePickerByType",
  props: [
    "template",
    "value", //input value
    "interval", //Specifies the interval between neighboring values in the popup list in minutes
    "type", //time,date,datetime
    "disabled", //field is disabled?
    "isReturnMaskedValue", // on value changed return masked value?
  ],
  data() {
    return {
      dateFormatInfo: this.template.parent.$root.getDateTimeFormat("short"),
      dateTimeFormatInfo: this.template.parent.$root.getDateTimeFormat(),
      displayFormat: null,
      placeholder: "",
      inputAttributes: {
        class: "form-control",
        id: this.template.id,
        name: this.template.id,
      },
      applyValueMode:
        this.type == "date" || this.type == "time" ? "instantly" : "useButtons",
    };
  },
  components: {
    DxDateBox,
  },
  mounted() {
    //
  },
  created() {
    switch (this.type) {
      case "time":
        this.displayFormat = this.dateFormatInfo.time.replace("A", "a");
        this.placeholder = this.$t(
          "BaseModelFields.ChooseTime",
          {},
          { locale: this.$store.state.activeLang }
        );
        break;
      case "date":
        this.displayFormat = this.dateFormatInfo.calendar
          .replace("DD", "dd")
          .replace("D", "d");
        this.placeholder = this.$t(
          "BaseModelFields.ChooseDate",
          {},
          { locale: this.$store.state.activeLang }
        );
        break;
      case "datetime":
        this.displayFormat = this.dateTimeFormatInfo.calendar
          .replace("A", "a")
          .replace("DD", "dd")
          .replace("D", "d");
        this.placeholder = this.$t(
          "BaseModelFields.ChooseDateAndTime",
          {},
          { locale: this.$store.state.activeLang }
        );
        break;
      default:
        break;
    }

    if (this.template.field.fieldType.includes("Formula")) {
      this.placeholder = this.$t(
        "BaseModelFields.FieldIsCalcForSystem",
        {},
        { locale: this.$store.state.activeLang }
      );
    }
  },
  methods: {
    onValueChanged(e) {
      var value = null;
      if (!String.isNullOrWhiteSpace(e.value)) {
        value = e.component._maskValue;
        if (this.isReturnMaskedValue) {
          value = e.component._changedValue;
        }
      }

      this.template.parent.editCellTemplateValueChangedForDatePickerComponents(
        this.template,
        true, //true //isCalculateField
        this.type,
        value
      );
    },
  },
};
</script>
