<template>
  <div
    class="view-edit-mode"
    :class="{
      hidden: isDetailPage,
    }"
  >
    <div
      class="position-relative dx-viewport"
      :class="{
        'dx-datetimepicker-valid': !isInvalid,
        'dx-datetimepicker-invalid': isInvalid,
      }"
    >
      <DxDateBox
        class="dx-datepicker-container"
        ref="dateTimePicker"
        :value="dateTimePickerValueAsDateTime"
        type="datetime"
        :displayFormat="displayFormat"
        :showClearButton="false"
        :disabled="!isEnabled"
        :openOnFieldClick="true"
        :input-attr="inputAttributes"
        :onValueChanged="onValueChanged"
        :useMaskBehavior="true"
        :interval="30"
        applyValueMode="useButtons"
      />
      <div class="invalid-tooltip">
        {{
          this.$t(
            "FieldWarnings.TextNotValid",
            {},
            { locale: this.$store.state.activeLang }
          )
        }}
      </div>
    </div>
  </div>
  <div class="view-detail-mode" v-if="isDetailPage">
    <span :id="field.publicId" class="d-flex text-break">{{
      String.isNullOrWhiteSpace(dateTimePickerValueAsString)
        ? $valueNotAvailableSeparator
        : dateTimePickerValueAsString
    }}</span>
  </div>
</template>
<script>
/* https://js.devexpress.com/Documentation/ApiReference/UI_Components/dxDateBox/Configuration/ */
import DxDateBox from "devextreme-vue/date-box";
export default {
  name: "DateTimePicker",
  data() {
    return {
      displayFormat: null,
      inputAttributes: {
        class: `form-control ${
          String.isNullOrWhiteSpace(this.element.valueClassNames)
            ? ""
            : this.element.valueClassNames
        }`,
        id: this.element.id,
        name: this.element.id,
      },
      formatInfo: this.$root.getDateTimeFormat(),
      shortFormatInfo: this.$root.getDateTimeFormat("short"),
      dateTimePickerValueAsDateTime: null,
      dateTimePickerValueAsString: null,
      isNewPage: this.crudType === "NEW",
      isDetailPage: this.crudType === "DETAIL",
      placeholder: this.field.fieldType.includes("Formula")
        ? this.$t(
            "BaseModelFields.FieldIsCalcForSystem",
            {},
            { locale: this.$store.state.activeLang }
          )
        : this.$t(
            "BaseModelFields.ChooseDateAndTime",
            {},
            { locale: this.$store.state.activeLang }
          ),
      oldValue: "",
      isEnabled:
        this.field.isActive &&
        !this.element.isDisabled &&
        !this.field.fieldType.includes("Formula") &&
        !this.field.fieldType.includes("Rollup"),
    };
  },
  created() {
    this.displayFormat = this.formatInfo.calendar
      .replace("A", "a")
      .replace("DD", "dd")
      .replace("D", "d");
  },
  components: {
    DxDateBox,
  },
  computed: {
    isInvalid() {
      return (
        (this.element.isRequired || this.field.isRequired) &&
        String.isNullOrWhiteSpace(this.dateTimePickerValueAsDateTime)
      );
    },
  },
  watch: {
    dateTimePickerValueAsDateTime() {
      if (String.isNullOrWhiteSpace(this.dateTimePickerValueAsDateTime)) {
        this.onCleared();
      }
    },
  },
  props: ["element", "field", "value", "crudType", "isCellEdit", "rowData"],
  methods: {
    onValueChanged(e) {
      if (String.isNullOrWhiteSpace(e.event)) return;

      /* UI Change Event Fix */
      this.$root.formItemNewChangeEvent([this.field.publicId]);

      this.dateTimePickerValueAsDateTime = e.value;

      if (!this.isCellEdit) {
        setTimeout(() => {
          this.$root.calculateTriggerField(this.field);
        }, 1000);
      }
    },
    setValue(isSuccess, date) {
      this.dateTimePickerValueAsDateTime = this.$moment(
        date,
        this.formatInfo.calendar
      ).format("yyyy-MM-DDTHH:mm:ss");

      if (isSuccess) {
        this.dateTimePickerValueAsString = date;
        this.oldValue = date;
      }
    },
    onCleared() {
      this.$root.childValuesEmpty(this.field.publicId);
    },
  },
  mounted() {
    if (
      !String.isNullOrWhiteSpace(this.value) &&
      this.value !== this.$formulaErrorText
    ) {
      this.dateTimePickerValueAsDateTime = this.$moment(
        this.value,
        this.formatInfo.calendar
      ).format("yyyy-MM-DDTHH:mm:ss");

      var formatedDatetime = this.$root.dateTimeFormat(this.value);
      this.dateTimePickerValueAsString = formatedDatetime;
      this.oldValue = formatedDatetime;
    }

    if (this.$route.meta.isEditPage && this.isEnabled) {
      this.isEnabled = this.field.isEditable;
    }

    var datePicker = document.getElementById(this.element.id);
    if (!String.isNullOrWhiteSpace(datePicker)) {
      if (this.element.isRequired || this.field.isRequired) {
        datePicker.setAttribute("required", "");
      }

      datePicker.setAttribute("placeholder", this.placeholder);
      datePicker.setAttribute("data-oldvalue", this.oldValue);
    }
  },
};
</script>
