import { createApp } from "vue";
import App from "./App.vue";

import router from "./router";
import store from "./store";

import "devextreme/dist/css/dx.light.css";
import "@/assets/css/devexpress-components.css";

//import custom js files
import "@/assets/js/set.js";
import "@/assets/js/windowSXFunctions.js";

//import css files
import "@/assets/css/main.css";
import "@/assets/css/sx-toggle.css";
import "@/assets/css/app-loader.css";
import "@/assets/css/leaflet.css";
import "@/assets/css/main-responsive.css";
import "@/assets/css/select2.bootstrap-5.css";
import "@/assets/css/fa-all.css";
import "@/assets/dashboard/css/sx-dashboard.css";

//import notify css file
import "mosha-vue-toastify/dist/style.css";
import "intl-tel-input/build/css/intlTelInput.css";

//imports for app initialization
import { initInlineSvg } from "@/core/plugins/inline-svg";
import { appAxios } from "@/core/axios/appAxios.js";
import { brsAxios } from "@/core/axios/brsAxios.js";
import { prodAppAxios } from "@/core/axios/prodAppAxios.js";
import { authAxios } from "@/core/axios/authAxios.js";

//import i18h(language)
import { createI18n, useI18n } from "vue-i18n/index";
import en from "./locales/en.json";
import tr from "./locales/tr.json";

const i18n = createI18n({
  legacy: false,
  warnHtmlMessage: false,
  locale: store.getters._activeLang,
  messages: {
    en: en,
    tr: tr,
  },
  numberFormats: {
    en: {
      currency: {
        style: "currency",
        currency: "USD",
        decimal: ".",
        group: ",",
      },
    },
    tr: {
      currency: {
        style: "currency",
        currency: "TRY",
        decimal: ",",
        group: ".",
      },
    },
  },
  datetimeFormats: {
    en: {
      short: {
        year: "numeric",
        month: "short",
        day: "numeric",
        calendar: "M/D/yyyy",
        time: "hh:mm:ss A",
      },
      long: {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
        hour: "2-digit",
        minute: "2-digit",
        second: "2-digit",
        calendar: "M/D/yyyy hh:mm:ss A",
        time: "hh:mm:ss A",
      },
    },
    tr: {
      short: {
        year: "numeric",
        month: "short",
        day: "numeric",
        calendar: "DD.MM.yyyy",
        time: "HH:mm:ss",
      },
      long: {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
        hour: "2-digit",
        minute: "2-digit",
        second: "2-digit",
        calendar: "DD.MM.yyyy HH:mm:ss",
        time: "HH:mm:ss",
      },
    },
  },
});
import { SX } from "@/assets/js/windowSXFunctions";

import setupInterceptors from "./core/axios/setupInterceptors";

import "bootstrap";
import { Tooltip } from "bootstrap";

new Tooltip(document.body, {
  selector: "[data-bs-toggle='tooltip']",
});

import Vue3MobileDetection from "vue3-mobile-detection";

import AppLoader from "@/components/custom/loader/AppLoader.vue";
import SetLoader from "@/components/custom/loader/SetLoader.vue";
import ActionButtons from "@/components/custom/shared/ActionButtons.vue";
import AlertBox from "@/components/custom/shared/AlertBox.vue";
import JsonViewerModal from "@/components/custom/json-viewer/JsonViewerModal.vue";
import DeleteModal from "@/components/custom/delete/DeleteModal.vue";
import Dropdown from "@/components/custom/dropdown/Dropdown.vue";
import ToggleButton from "@/components/custom/button/Toggle.vue";
// import SyncfusionGrid from "@/components/syncfusion/grid/Grid.vue";
import DevExpressGrid from "@/components/devexpress/grid/remote/Grid.vue";
import moment from "moment";
import cryptoJS from "crypto-js";

//Note: n: number format d: datetime format
const app = createApp(App, {
  setup() {
    const { n, d } = useI18n();
    return { n, d };
  },
});

app.component("AppLoader", AppLoader);
app.component("SetLoader", SetLoader);
app.component("ActionButtons", ActionButtons);
app.component("AlertBox", AlertBox);
app.component("JsonViewerModal", JsonViewerModal);
app.component("DeleteModal", DeleteModal);
app.component("Dropdown", Dropdown);
app.component("ToggleButton", ToggleButton);
// app.component("SyncfusionGrid", SyncfusionGrid);
app.component("DevExpressGrid", DevExpressGrid);

setupInterceptors(store, router);

app.use(store);
app.use(router);
app.use(Vue3MobileDetection, {});
app.use(i18n);

initInlineSvg(app);

//app global axios instance(s)
app.config.globalProperties.$authAxios = authAxios;
app.config.globalProperties.$appAxios = appAxios;
app.config.globalProperties.$prodAppAxios = prodAppAxios;
app.config.globalProperties.$brsAxios = brsAxios;

app.config.globalProperties.$moment = moment;
app.config.globalProperties.$i18n = i18n;
app.config.globalProperties.$SXWindowFunctions = SX;

app.config.globalProperties.$uiProviderCompany =
  process.env.VUE_APP_UI_PROVIDER_COMPANY;
app.config.globalProperties.$syncfusionCompanyKey =
  process.env.VUE_APP_UI_PROVIDER_SYNCFUSION_KEY;
app.config.globalProperties.$devExpressCompanyKey =
  process.env.VUE_APP_UI_PROVIDER_DEVEXPRESS_KEY;

//app global properties
app.config.globalProperties.$systemFieldPrefix =
  process.env.VUE_APP_SYSTEM_FIELD_PREFIX;
app.config.globalProperties.$isAutoComplete =
  process.env.VUE_APP_TEXBOXES_AUTOCOMPLETE;
app.config.globalProperties.$systemSeparator =
  process.env.VUE_APP_SYSTEM_SEPARATOR;
app.config.globalProperties.$selectSeparator =
  process.env.VUE_APP_SYSTEM_SELECT_SEPARATOR;
app.config.globalProperties.$isTextSpellCheck =
  process.env.VUE_APP_TEXTBOXES_SPELLCHECK;
app.config.globalProperties.$formulaErrorText =
  process.env.VUE_APP_SYSTEM_FORMULA_ERROR_TEXT;
app.config.globalProperties.$valueNotAvailableSeparator =
  process.env.VUE_APP_SYSTEM_VALUENOTAVAILABLE_SEPARATOR;
app.config.globalProperties.$fieldPublicIdFormulaExtension =
  process.env.VUE_APP_SYSTEM_PUBLICID_FORMULA_EXTENSION;
app.config.globalProperties.$setXRMReportAuthorizeActionUrl =
  process.env.VUE_APP_REPORT_WEB_SITE_AUTHORIZE_ACTION_URL;
app.config.globalProperties.$appHomeUrl = process.env.VUE_APP_HOME_ADDRESS_URL;
app.config.globalProperties.$cryptoJs = cryptoJS;
app.config.globalProperties.$cryptoSecretKey =
  process.env.VUE_APP_CRYPTO_SECRET_KEY;
app.config.globalProperties.$cryptoIvKey = process.env.VUE_APP_CRYPTO_IV_KEY;
app.config.globalProperties.$listPageGridId =
  process.env.VUE_APP_LIST_PAGE_GRID_UNIQUE_ID;
app.config.globalProperties.$viewFilterFormulaErrorKey =
  process.env.VUE_APP_VIEWFILTER_FORMULA_ERROR_KEY;
app.config.globalProperties.$buttonValidationCalculateErrorKey =
  process.env.VUE_APP_BUTTON_VALIDATION_CALCULATE_ERROR_KEY;

app.config.globalProperties.$dataExportMaxPageSize =
  process.env.VUE_APP_DataExportSettings__GetRecordListSize;

app.config.warnHandler = function (msg, vm, trace) {
  return null;
};

import { getAppConfigurationFile } from "@/core/config/app-config.js";
getAppConfigurationFile().then((configFile) => {
  app.config.globalProperties.$config = configFile;

  const isDevelopment = process.env.NODE_ENV === "development",
    configIsCustomConfig = configFile.isCustomConfig;

  if (!isDevelopment) {
    authAxios.defaults.baseURL = !configIsCustomConfig
      ? process.env.VUE_APP_API_URL
      : configFile.api.gateway;
    appAxios.defaults.baseURL = !configIsCustomConfig
      ? process.env.VUE_APP_API_URL
      : configFile.api.gateway;
    brsAxios.defaults.baseURL = !configIsCustomConfig
      ? process.env.VUE_APP_BRS_API_URL
      : configFile.api.brs;
    prodAppAxios.defaults.baseURL = !configIsCustomConfig
      ? process.env.VUE_APP_PROD_API_URL
      : configFile.api.prod;
  }

  app.mount("#app");
});
