<template>
  <div class="view-edit-mode" :class="{ hidden: isDetailPage }">
    <div class="position-relative">
      <input
        v-model="percentInputValue"
        ref="percentInput"
        type="text"
        class="form-control text-end"
        :autocomplete="$isAutoComplete"
        :spellcheck="$isTextSpellCheck"
        :maxlength="maxLength"
        :class="
          element.valueClassNames !== undefined ? element.valueClassNames : ''
        "
        :id="element.id"
        :name="element.id"
        :disabled="isDisabled"
        :required="isRequired"
        :placeholder="placeholder"
        :data-oldvalue="oldValue"
        @keypress="numbersOnly"
      />
      <div class="invalid-tooltip">
        {{
          this.$t(
            "FieldWarnings.TextNotValid",
            {},
            { locale: this.$store.state.activeLang }
          )
        }}
      </div>
    </div>
  </div>
  <div class="view-detail-mode" v-if="isDetailPage">
    <span
      class="d-flex text-break"
      v-if="!String.isNullOrWhiteSpace(percentInputValue)"
      >{{ percentInputValue }} %</span
    >
    <span v-else>{{ $valueNotAvailableSeparator }}</span>
  </div>
</template>
<script>
export default {
  name: "PercentInput",
  props: ["element", "field", "value", "crudType", "isCellEdit", "rowData"],
  data() {
    return {
      percentInputValue: this.value,
      isRequired: this.field.isRequired || this.element.isRequired,
      isDisabled:
        !this.field.isActive ||
        this.element.isDisabled ||
        (this.$route.meta.isEditPage && !this.field.isEditable),
      isNewPage: this.crudType === "NEW",
      isDetailPage: this.crudType === "DETAIL",
      placeholder: "",
      maxLength: String.isNullOrWhiteSpace(this.field.inputMaskPattern.length)
        ? 3
        : this.field.inputMaskPattern.length,
      oldValue: "",
    };
  },
  methods: {
    setValue(isSuccess, value) {
      this.percentInputValue = value;
      if (isSuccess) {
        this.oldValue = value;
      }
    },
    numbersOnly(evt) {
      return Number.isNumber(evt);
    },
  },
  mounted() {
    if (
      !this.field.fieldType.includes("Formula") &&
      !String.isNullOrWhiteSpace(this.value)
    ) {
      this.percentInput = this.value;
      this.oldValue = this.value;
    }
  },
};
</script>
