<template>
  <div
    class="view-edit-mode"
    :class="{
      hidden: $route.meta.isDetailPage && !isCellEdit,
      'mb-2': $route.meta.isDetailPage && !isCellEdit,
    }"
  >
    <div class="form-check form-switch">
      <input
        ref="checkbox"
        class="form-check-input"
        type="checkbox"
        :id="element.id"
        :name="element.id"
        v-model="checkBoxValue"
        :class="
          element.valueClassNames !== undefined ? element.valueClassNames : ''
        "
        :disabled="isDisabled"
        :required="isRequired"
        :data-oldvalue="oldValue"
        @change="checkboxOnChange"
      />
    </div>
  </div>
  <div class="view-detail-mode" v-if="$route.meta.isDetailPage && !isCellEdit">
    <span class="d-flex">
      <i class="fa fa-check text-success fs-4" v-if="checkBoxValue"></i>
      <i class="fa fa-times text-danger fs-4" v-else></i>
    </span>
  </div>
</template>
<script>
export default {
  name: "Checkbox",
  props: ["element", "field", "value", "crudType", "isCellEdit", "rowData"],
  data() {
    return {
      checkBoxValue: this.value,
      isRequired:
        (this.element.isRequired || this.field.isRequired) &&
        this.field.formulaName !== "ISACTIVE",
      isDisabled:
        !this.field.isActive ||
        this.element.isDisabled ||
        (this.$route.meta.isEditPage && !this.field.isEditable),
      oldValue: false,
    };
  },
  methods: {
    setValue(isSuccess, value) {
      this.checkBoxValue = Boolean.toBool(value.toString());
      if (isSuccess) {
        this.oldValue = value;
      }
    },
    checkboxOnChange() {
      if (!this.isCellEdit && !this.$route.meta.isDetailPage) {
        this.$root.calculateTriggerField(this.field);
      }
    },
  },
  mounted() {
    this.checkBoxValue = Boolean.toBool(this.value.toString());
    this.oldValue = this.checkBoxValue;
  },
};
</script>
