<template>
  <div
    class="modal fade"
    :id="`modalBulkUpdate_${id}`"
    data-bs-backdrop="static"
    data-bs-keyboard="false"
    :aria-labelledby="`modalBulkUpdateLabel_${id}`"
    aria-hidden="true"
    tabindex="-1"
  >
    <div
      class="modal-dialog sx-modal-responsive"
      :class="{ 'modal-full-width': !this.$isMobile() }"
    >
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" :id="`modalBulkUpdateLabel_${id}`">
            <i class="fas fa-edit"></i>
            {{
              $t(
                "Components.BulkUpdateModal.Title",
                {},
                { locale: this.$store.state.activeLang }
              )
            }}
          </h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <div class="card border-primary">
            <div
              class="card-header bg-primary text-white align-middle justify-content-start align-items-center p-3 cursor-pointer"
              @click="
                triggerSettingPanelBodyIsCollapse =
                  !triggerSettingPanelBodyIsCollapse
              "
            >
              <i class="bi bi-sliders fs-4 me-2"></i>
              {{
                $t(
                  "Components.BulkUpdateModal.TriggerSettings",
                  {},
                  { locale: this.$store.state.activeLang }
                )
              }}
            </div>
            <div
              class="card-body text-primary"
              :class="{
                collapse: triggerSettingPanelBodyIsCollapse,
              }"
            >
              <div class="row row-cols-1 mt-2 mb-3">
                <div class="col-md-3">
                  <label
                    for="DoNotCalculateRollupFields"
                    class="form-label fw-bolder"
                    >{{
                      $t(
                        "Components.BulkUpdateModal.DoNotCalculateRollupFields",
                        {},
                        { locale: this.$store.state.activeLang }
                      )
                    }}</label
                  >
                  <div class="form-check form-switch">
                    <input
                      class="form-check-input"
                      type="checkbox"
                      id="DoNotCalculateRollupFields"
                      v-model="model.doNotCalculateRollupFields"
                    />
                  </div>
                </div>
                <div class="col-md-3">
                  <label
                    for="DoNotCalculateFormulaFields"
                    class="form-label fw-bolder"
                    >{{
                      $t(
                        "Components.BulkUpdateModal.DoNotCalculateFormulaFields",
                        {},
                        { locale: this.$store.state.activeLang }
                      )
                    }}</label
                  >
                  <div class="form-check form-switch">
                    <input
                      class="form-check-input"
                      type="checkbox"
                      id="DoNotCalculateFormulaFields"
                      v-model="model.doNotCalculateFormulaFields"
                    />
                  </div>
                </div>
                <div class="col-md-3">
                  <label
                    for="DoNotTriggerBusinessRule"
                    class="form-label fw-bolder"
                    >{{
                      $t(
                        "Components.BulkUpdateModal.DoNotTriggerBusinessRule",
                        {},
                        { locale: this.$store.state.activeLang }
                      )
                    }}</label
                  >
                  <div class="form-check form-switch">
                    <input
                      class="form-check-input"
                      type="checkbox"
                      id="DoNotTriggerBusinessRule"
                      v-model="model.doNotTriggerBusinessRule"
                    />
                  </div>
                </div>
                <div class="col-md-3">
                  <label for="InstantStart" class="form-label fw-bolder">{{
                    $t(
                      "Components.BulkUpdateModal.InstantStart",
                      {},
                      { locale: this.$store.state.activeLang }
                    )
                  }}</label>
                  <div class="form-check form-switch">
                    <input
                      class="form-check-input"
                      type="checkbox"
                      id="InstantStart"
                      @change="instantStartOnChange"
                      v-model="model.instantStart"
                    />
                  </div>
                </div>
              </div>
              <div class="row row-cols-1 mt-4 mb-2">
                <div class="col col-md-4">
                  <label for="Description" class="form-label fw-bolder"
                    >{{
                      $t(
                        "Components.BulkUpdateModal.Description",
                        {},
                        { locale: this.$store.state.activeLang }
                      )
                    }}
                  </label>
                  <textarea
                    id="Description"
                    class="form-control"
                    rows="1"
                    v-model="model.description"
                  ></textarea>
                </div>
                <div class="col col-md-4">
                  <label for="Email" class="form-label fw-bolder required"
                    >{{
                      $t(
                        "Components.BulkUpdateModal.Email",
                        {},
                        { locale: this.$store.state.activeLang }
                      )
                    }}
                  </label>
                  <input
                    type="email"
                    class="form-control"
                    id="Email"
                    v-model="model.email"
                  />
                </div>
                <div class="col col-md-4" v-if="!model.instantStart">
                  <label
                    for="startAt"
                    class="form-label fw-bolder"
                    :class="{ required: !model.instantStart }"
                    >{{
                      $t(
                        "Components.BulkUpdateModal.StartAt",
                        {},
                        { locale: this.$store.state.activeLang }
                      )
                    }}</label
                  >
                  <DatePicker
                    ref="startDate"
                    type="datetime"
                    id="startAt"
                    :isDisabled="model.instantStart"
                    @valueChanged="onValueChangedStartDate"
                  />
                </div>
              </div>
            </div>
          </div>
          <div class="card border-dark mt-3">
            <div
              class="card-header bg-dark text-white align-middle justify-content-start align-items-center p-3 cursor-pointer"
              @click="
                matchFieldPanelBodyIsCollapse = !matchFieldPanelBodyIsCollapse
              "
            >
              <i class="bi bi bi-arrow-left-right fs-4 me-2"></i>
              {{
                $t(
                  "Components.BulkUpdateModal.MappingTable",
                  {},
                  { locale: this.$store.state.activeLang }
                )
              }}
            </div>
            <div
              class="card-body text-dark"
              :class="{
                collapse: matchFieldPanelBodyIsCollapse,
              }"
            >
              <div class="table-responsive mt-1">
                <form id="bulkUpdateRowForm" class="form">
                  <table class="table table-warning table-filter mb-0">
                    <thead>
                      <tr>
                        <th class="col-md-5">
                          <label class="required">
                            {{
                              $t(
                                "Components.BulkUpdateModal.FieldName",
                                {},
                                { locale: this.$store.state.activeLang }
                              )
                            }}</label
                          >
                        </th>
                        <th class="col-md-5">
                          <label
                            :class="{
                              required: field && field.isRequired,
                            }"
                          >
                            {{
                              $t(
                                "Components.BulkUpdateModal.Value",
                                {},
                                { locale: this.$store.state.activeLang }
                              )
                            }}
                          </label>
                        </th>
                        <th class="text-center col-md-2"></th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <Select2
                            :placeholder="
                              $t(
                                'BaseModelFields.Choose',
                                {},
                                { locale: this.$store.state.activeLang }
                              )
                            "
                            id="bulkUpdateField"
                            :settings="fieldSelect2Settings"
                            @select="fieldChange($event)"
                          />
                        </td>
                        <td>
                          <input
                            type="text"
                            class="form-control"
                            disabled
                            v-if="!field"
                          />
                          <template v-else>
                            <template
                              v-if="
                                $root.fieldTypes.select.includes(
                                  field.fieldType
                                ) ||
                                $root.fieldTypes.checkbox.includes(
                                  field.fieldType
                                )
                              "
                            >
                              <Select2
                                :placeholder="
                                  $t(
                                    'BaseModelFields.Choose',
                                    {},
                                    { locale: this.$store.state.activeLang }
                                  )
                                "
                                id="bulkUpdateFieldValue"
                                :settings="fieldSelect2Settings"
                                @select="fieldValueChange($event)"
                              />
                            </template>
                            <template
                              v-else-if="
                                $root.fieldTypes.date.includes(field.fieldType)
                              "
                            >
                              <DatePicker
                                type="date"
                                id="bulk-update-modal-date-field"
                                :isReturnMaskedValue="true"
                                @valueChanged="fieldValueChangeForDatePickers"
                              />
                            </template>
                            <template
                              v-else-if="
                                $root.fieldTypes.datetime.includes(
                                  field.fieldType
                                )
                              "
                            >
                              <DatePicker
                                type="datetime"
                                id="bulk-update-modal-datetime-field"
                                :isReturnMaskedValue="true"
                                @valueChanged="fieldValueChangeForDatePickers"
                              />
                            </template>
                            <template
                              v-else-if="
                                $root.fieldTypes.time.includes(field.fieldType)
                              "
                            >
                              <DatePicker
                                type="time"
                                id="bulk-update-modal-time-field"
                                :isReturnMaskedValue="true"
                                @valueChanged="fieldValueChangeForDatePickers"
                              />
                            </template>
                            <template
                              v-else-if="
                                $root.fieldTypes.number.includes(
                                  field.fieldType
                                )
                              "
                            >
                              <input
                                type="text"
                                class="form-control text-end"
                                id="bulk-update-modal-number-field"
                                v-model="fieldValue"
                              />
                            </template>
                            <template
                              v-else-if="
                                $root.fieldTypes.phone.includes(field.fieldType)
                              "
                            >
                              <div class="position-relative">
                                <input
                                  type="tel"
                                  class="form-control sx-phone-control"
                                  :id="phoneFieldInputId"
                                  v-model="fieldValue"
                                />
                              </div>
                            </template>
                            <template v-else>
                              <input
                                class="form-control"
                                :type="`${
                                  field.fieldType == 'Email' ? 'email' : 'text'
                                }`"
                                id="bulk-update-modal-text-field"
                                v-model="fieldValue"
                              />
                            </template>
                          </template>
                        </td>
                        <td class="align-middle">
                          <button
                            type="button"
                            class="btn btn-primary w-100 h-41"
                            :disabled="!field"
                            @click="add"
                          >
                            <i class="fa fa-plus"></i>
                            {{
                              $t(
                                "Components.BulkUpdateModal.Add",
                                {},
                                { locale: this.$store.state.activeLang }
                              )
                            }}
                          </button>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </form>
              </div>
              <div class="col-lg-12 mt-4" v-if="model.keyValueList.length">
                <div class="table-responsive">
                  <table
                    class="table table-filter table-striped table-bordered table-primary table-hover mb-0"
                  >
                    <thead>
                      <tr>
                        <th class="col-md-5">
                          <label class="">
                            {{
                              $t(
                                "Components.BulkUpdateModal.FieldName",
                                {},
                                { locale: this.$store.state.activeLang }
                              )
                            }}</label
                          >
                        </th>
                        <th class="col-md-5">
                          {{
                            $t(
                              "Components.BulkUpdateModal.Value",
                              {},
                              { locale: this.$store.state.activeLang }
                            )
                          }}
                        </th>
                        <th class="text-center col-md-2">
                          {{
                            $t(
                              "BaseModelFields.Commands",
                              {},
                              { locale: this.$store.state.activeLang }
                            )
                          }}
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        class="criteria-item"
                        v-for="(item, i) in model.keyValueList"
                        :key="i"
                      >
                        <td class="align-middle">
                          {{ item.field.name }}
                        </td>
                        <td class="align-middle">
                          <template
                            v-if="
                              ($root.fieldTypes.select.includes(
                                item.field.fieldType
                              ) ||
                                $root.fieldTypes.checkbox.includes(
                                  item.field.fieldType
                                )) &&
                              !String.isNullOrWhiteSpace(item.value)
                            "
                          >
                            <div v-if="item.value.includes($systemSeparator)">
                              <span
                                class="badge bg-primary me-2 mb-1 fs-6"
                                v-for="(value, index) in item.value.split(
                                  $systemSeparator
                                )"
                                :key="value"
                              >
                                {{ item.text.split($systemSeparator)[index] }}
                              </span>
                            </div>
                            <span v-else>
                              {{ item.text }}
                            </span>
                          </template>
                          <template v-else>
                            {{ item.value }}
                          </template>
                        </td>
                        <td class="align-middle" align="center">
                          <button
                            type="button"
                            class="btn btn-sm btn-danger"
                            @click="remove(item.key)"
                          >
                            <i class="fa fa-trash"></i>
                            {{
                              $t(
                                "Components.BulkUpdateModal.Remove",
                                {},
                                { locale: this.$store.state.activeLang }
                              )
                            }}
                          </button>
                        </td>
                      </tr>
                      <tr>
                        <td colspan="3">
                          {{
                            $t(
                              "Components.BulkUpdateModal.SumInformation",
                              {},
                              { locale: this.$store.state.activeLang }
                            ).replaceAll(
                              "ITEM_LENGTH",
                              model.keyValueList.length
                            )
                          }}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="btn btn-success btn-sm btn-bulk-update-submit"
            @click="updateAll"
          >
            <span>
              <i class="fas fa-edit"></i>
              {{
                $t(
                  "Components.BulkUpdateModal.Update",
                  {},
                  { locale: this.$store.state.activeLang }
                )
              }}
            </span>
          </button>
          <button
            type="button"
            class="btn btn-danger btn-sm"
            data-bs-dismiss="modal"
          >
            <i class="fa fa-times"></i>
            {{
              $t(
                "BaseModelFields.Close",
                {},
                { locale: this.$store.state.activeLang }
              )
            }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { hideModal } from "@/core/helpers/dom";
import DatePicker from "@/components/devexpress/dateboxes/DatePickerByType.vue";
import { createToast } from "mosha-vue-toastify";
import Inputmask from "inputmask";
import $ from "jquery";
import Select2 from "vue3-select2-component";
export default {
  name: "BulkUpdateModal",
  props: ["id", "table", "isTable", "pageData"],
  components: {
    Select2,
    DatePicker,
  },
  data() {
    return {
      actionName: "Lcdp-BulkImportFromData",
      allFields: [],
      field: null,
      fieldValue: null,
      fieldText: null,
      fieldSelect2Settings: {
        allowClear: true,
        disabled: false,
        multiple: false,
        dropdownAutoWidth: false,
        separator: this.$systemSeparator,
        theme: "bootstrap-5",
      },
      phoneFieldInputId: "bulk-update-modal-tel-field",
      triggerSettingPanelBodyIsCollapse: false,
      matchFieldPanelBodyIsCollapse: false,
      model: {
        customObjectKey: this.isTable
          ? this.table.lookupObjectUrlName
          : this.$route.params.key,
        isUpdateRequest: true,
        parentRecordPublicId: this.isTable ? this.$route.params.id : null,
        lookupRelationPublicId: this.isTable ? this.table.publicId : null,
        lookupRelationFormulaName: this.isTable ? this.table.formulaName : null,
        requestFromId: this.isTable ? 12 : 11,
        processTypeId: 2,
        processTypeName: "Update",
        isAdhoc: String.isNullOrWhiteSpace(this.$route.query.isAdhoc)
          ? false
          : Boolean.toBool(this.$route.query.isAdhoc),
        viewFilterPublicId: this.pageData.filter.publicId,
        description: "",
        email: this.$store.getters.email,
        instantStart: true,
        startAt: null,
        keyValueList: [],
        doNotCalculateFormulaFields: false,
        doNotCalculateRollupFields: false,
        doNotTriggerBusinessRule: false,
      },
      responseError: [],
      numberFormatInfo: this.$root.getNumberFormat(),
      searchButtonHtml: String.format(
        '<span class="loading-block"><span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span><span> {0}</span></span>',
        this.$t(
          "BaseModelFields.PleaseWait",
          {},
          { locale: this.$store.state.activeLang }
        )
      ),
    };
  },
  methods: {
    onValueChangedStartDate(value) {
      this.model.startAt = this.$moment(value).format();
    },
    defaultValues() {
      this.field = null;
      this.fieldValue = null;
      this.fieldText = null;
      $("#bulkUpdateField").find("option").remove();
    },
    defaultModel() {
      this.model = {
        customObjectKey: this.$route.params.key,
        isUpdateRequest: true,
        requestFromId: 11,
        processTypeId: 2,
        processTypeName: "Update",
        isAdhoc: String.isNullOrWhiteSpace(this.$route.query.isAdhoc)
          ? false
          : Boolean.toBool(this.$route.query.isAdhoc),
        viewFilterPublicId: this.pageData.filter.publicId,
        description: "",
        email: this.$store.getters.email,
        instantStart: true,
        startAt: null,
        keyValueList: [],
        doNotCalculateFormulaFields: false,
        doNotCalculateRollupFields: false,
        doNotTriggerBusinessRule: false,
      };
    },
    remove(key) {
      this.model.keyValueList = this.model.keyValueList.filter(function (item) {
        return item.key !== key;
      });
    },
    fieldChange(selectedValue) {
      if (!selectedValue.selected) {
        this.defaultValues();
        return;
      }

      this.field = this.allFields.find((f) => f.publicId === selectedValue.id);
      if (this.field) {
        this.fieldOperation();
      } else {
        this.getFields().then((r) => {
          this.fieldChange(selectedValue);
        });
      }
    },
    fieldValueChange(selectedValue) {
      var el = $("#bulkUpdateFieldValue"),
        elData = el.select2("data");

      this.fieldValue = elData.map((u) => u.id).join(this.$systemSeparator);
      this.fieldText = elData.map((u) => u.text).join(this.$systemSeparator);
    },
    fieldValueChangeForDatePickers(value) {
      this.fieldValue = value;
    },
    getSelectRequestUrl() {
      var ajaxUrl = null;
      switch (this.field.fieldType) {
        case "Predefined":
          ajaxUrl = this.$root.getFieldRequestUrl("predefined", {
            parentId: this.field.predefinedPublicId,
          });
          break;
        case "OrganizationalUnit":
        case "FormulaOrganizationalUnit":
          ajaxUrl = this.$root.getFieldRequestUrl("organizationalunit", {
            publicId: this.field.publicId,
            name: this.field.organizationalUnitName,
            filterType: this.field.organizationalUnitFilterType,
            groupIds: this.field.organizationalUnitGroupPublicIds,
            includeItself: this.field.organizationalUnitIncludeItself,
            depth: this.field.organizationalUnitDepth,
            isAddCurrentKeys: true,
          });
          break;
        case "SelectList":
        case "Checkbox":
          ajaxUrl = this.$root.getFieldRequestUrl("select", {
            id: this.field.publicId,
          });
          break;
        case "Lookup":
          ajaxUrl = this.$root.getFieldRequestUrl("lookup", {
            coId: this.field.customObjectPublicId,
            fieldPublicId: this.field.publicId,
          });
          break;
        default:
          break;
      }

      if (String.isNullOrWhiteSpace(ajaxUrl)) {
        // console.error("getSelectRequestUrl Ajax Url missing");
        return;
      }

      return ajaxUrl;
    },
    fieldOperation() {
      if (this.field.fieldType == "Phone") {
        setTimeout(() => {
          this.$root.preparePhoneNumber(this.phoneFieldInputId);
        }, 100);
      } else if (this.field.fieldType == "Number") {
        setTimeout(() => {
          var numericTextBox = document.getElementById(
            "bulk-update-modal-number-field"
          );
          var im = new Inputmask({
            alias: "decimal",
            groupSeparator: this.numberFormatInfo.group,
            radixPoint: this.numberFormatInfo.decimal,
            autoGroup: this.field.isThousandSeparator,
            clearMaskOnLostFocus: true,
            allowMinus: true,
            radixFocus: true,
            positionCaretOnClick: "none",
            autoUnmask: false,
            integerDigits: this.field.length,
            digits: this.field.decimalPlaces,
          });
          im.mask(numericTextBox);

          //format placeholder
          var formatInfo = this.numberFormatInfo,
            word = String.replaceAll(
              this.field.inputMaskPattern,
              "+",
              formatInfo.decimal
            );
          word = String.replaceAll(word, "*", formatInfo.group);
          word = String.replaceAll(word, "#", "0");
          numericTextBox.setAttribute("placeholder", word);
        }, 100);
      } else if (
        this.$root.fieldTypes.select.includes(this.field.fieldType) ||
        this.$root.fieldTypes.checkbox.includes(this.field.fieldType)
      ) {
        setTimeout(() => {
          $("#bulkUpdateFieldValue").select2({
            language: this.$root.getSelect2Locale(this.$store.state.activeLang),
            allowClear: true,
            disabled: false,
            multiple: this.field.isMultipleValue,
            dropdownAutoWidth: false,
            placeholder: this.$t(
              "BaseModelFields.Choose",
              {},
              { locale: this.$store.state.activeLang }
            ),
            separator: this.$systemSeparator,
            theme: "bootstrap-5",
            minimumInputLength: 0,
            dropdownParent: $(`#modalBulkUpdate_${this.id}`),
            ajax: this.$root.getSelect2AjaxConfiguration(
              this.field,
              this.getSelectRequestUrl()
            ),
          });
        }, 100);
      }
    },
    async getFields() {
      return await this.$appAxios
        .post("/Lcdp-GetCustomObjectFieldsCacheValueById", {
          parentPublicId: this.pageData.customObject.publicId,
        })
        .then((response) => {
          this.allFields = response.data;
        })
        .catch(function (error) {
          // alert(error);
        });
    },
    add() {
      var form = $("form#bulkUpdateRowForm");
      form.addClass("was-validated");
      if (
        !form[0].checkValidity() ||
        !this.field ||
        !this.$root.forceFormValidation(form)
      ) {
        return;
      }

      var value = this.fieldValue,
        text = this.fieldText;
      if (this.field.fieldType == "Phone") {
        value = $(`[name="${this.phoneFieldInputId}_full_phone_number"]`).val();
        text = value;
      }

      var obj = {
        key: this.field.formulaName,
        value: value,
        text: text,
        field: this.field,
      };

      var itemsIsNotUnique = this.model.keyValueList.find(
        (f) => f.key == obj.key
      );
      if (this.field.isRequired && String.isNullOrWhiteSpace(obj.value)) {
        createToast(
          this.$t(
            "Components.BulkUpdateModal.ValidationForRequiredField",
            {},
            { locale: this.$store.state.activeLang }
          ),
          {
            showIcon: true,
            position: "top-right",
            type: "danger",
            transition: "zoom",
          }
        );
        return;
      } else if (itemsIsNotUnique) {
        createToast(
          this.$t(
            "Components.BulkUpdateModal.ValidationForNotUniqueFields",
            {},
            { locale: this.$store.state.activeLang }
          ),
          {
            showIcon: true,
            position: "top-right",
            type: "danger",
            transition: "zoom",
          }
        );
        return;
      }

      this.model.keyValueList.push(obj);
      this.defaultValues();
    },
    validation() {
      if (String.isNullOrWhiteSpace(this.model.email)) {
        createToast(
          this.$t(
            "Components.BulkUpdateModal.ValidationForEmail",
            {},
            { locale: this.$store.state.activeLang }
          ),
          {
            showIcon: true,
            position: "top-right",
            type: "danger",
            transition: "zoom",
          }
        );
        return false;
      } else if (!String.isEmail(this.model.email)) {
        createToast(
          this.$t(
            "FieldWarnings.EmailNotValid",
            {},
            { locale: this.$store.state.activeLang }
          ),
          {
            showIcon: true,
            position: "top-right",
            type: "danger",
            transition: "zoom",
          }
        );
      } else if (
        !this.model.instantStart &&
        String.isNullOrWhiteSpace(this.model.startAt)
      ) {
        createToast(
          this.$t(
            "Components.BulkUpdateModal.ValidationForStartAt",
            {},
            { locale: this.$store.state.activeLang }
          ),
          {
            showIcon: true,
            position: "top-right",
            type: "danger",
            transition: "zoom",
          }
        );
        return false;
      } else if (this.model.keyValueList.length == 0) {
        createToast(
          this.$t(
            "Components.BulkUpdateModal.ValidationForItems",
            {},
            { locale: this.$store.state.activeLang }
          ),
          {
            showIcon: true,
            position: "top-right",
            type: "danger",
            transition: "zoom",
          }
        );
        return false;
      }
      return true;
    },
    updateAll() {
      if (!this.validation()) {
        return;
      }

      var button = $(String.format(".btn-bulk-update-submit")),
        firstSpan = button.find("span:first"),
        loadingBlock = button.find(".loading-block");

      button.prop("disabled", true);
      firstSpan.hide();
      if (loadingBlock.length === 0) {
        firstSpan.after(this.searchButtonHtml);
        loadingBlock = button.find(".loading-block");
      } else {
        loadingBlock.show();
      }

      var model = { ...this.model };
      model.keyValueList = model.keyValueList.map(function (m) {
        return {
          key: m.key,
          value: m.value,
        };
      });

      this.responseError = [];
      this.$appAxios
        .post(this.actionName, model)
        .then((response) => {
          firstSpan.show();
          loadingBlock.hide();
          button.prop("disabled", false);

          var result = response.data;
          if (result.isOk) {
            this.defaultModel();
            hideModal(document.getElementById("modalBulkUpdate"));
            createToast(
              this.$t(
                "BaseModelFields.SuccessMessage",
                {},
                { locale: this.$store.state.activeLang }
              ),
              {
                showIcon: true,
                position: "top-right",
                type: "success",
                transition: "zoom",
              }
            );
          } else {
            createToast(result.msg, {
              showIcon: true,
              position: "top-right",
              type: "danger",
              transition: "zoom",
            });
          }
        })
        .catch(function (error) {
          firstSpan.show();
          loadingBlock.hide();
          button.prop("disabled", false);
          // alert(error);
        });
    },
  },
  mounted() {
    // this.getFields();

    if (this.model.isAdhoc) {
      this.model.viewFilterPublicId = this.$route.query.filterId;
    }

    $("#bulkUpdateField").select2({
      language: this.$root.getSelect2Locale(this.$store.state.activeLang),
      allowClear: true,
      disabled: false,
      multiple: false,
      dropdownAutoWidth: false,
      placeholder: this.$t(
        "BaseModelFields.Choose",
        {},
        { locale: this.$store.state.activeLang }
      ),
      separator: this.$systemSeparator,
      theme: "bootstrap-5",
      minimumInputLength: 0,
      dropdownParent: $(`#modalBulkUpdate_${this.id}`),
      ajax: this.$root.getSelect2AjaxConfiguration(
        { fieldType: "NotSelectlist" },
        String.format(
          "rws-FilterableFields?parentId={0}",
          this.pageData.customObject.publicId
        )
      ),
    });
  },
};
</script>
